<template>
  <div>
    <div v-if="!inventoryInfoApiDataIsLoading">
      <div class="row">
        <div class="col-12" v-if="inventoryInfoApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{inventoryInfoApiDataErrorMessage}}
          </div>
        </div>
        <div class="col-12" v-if="inventoryDocumentDownloadUrlApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{inventoryDocumentDownloadUrlApiDataErrorMessage}}
          </div>
        </div>
      </div>
      <div v-if="inventoryInfoApiData != null">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="profile">
              <div class="profile-header">

                <div class="profile-header-cover"></div>

                <div class="profile-header-content">

                  <div class="profile-header-img">
                    <img src="/assets/img/document.png" alt="">
                  </div>

                  <div class="profile-header-info">
                    <h1 class="m-t-10 m-b-5">{{ inventoryInfoApiData.type_name }}</h1>
                    <h3 class="m-b-5">{{ inventoryInfoApiData.server_side_code }} du
                      {{ inventoryInfoApiData.formatted_creation_date }}</h3>
                    <h3 class="m-b-5">{{ inventoryInfoApiData.warehouse_name }}</h3>
                  </div>

                </div>


                <ul class="profile-header-tab nav nav-tabs">
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('home')"
                                          v-bind:class="{ 'active': tab.home }" class="nav-link"
                                          data-toggle="tab">TOUS LES ARTICLES
                    ({{ inventoryInfoApiData.inventory_items_count }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('equal')"
                                          v-bind:class="{ 'active': tab.equal }" class="nav-link"
                                          data-toggle="tab">BON COMPTE
                    ({{ inventoryInfoApiData.inventory_items_count_equal }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('gaps')"
                                          v-bind:class="{ 'active': tab.gaps }" class="nav-link"
                                          data-toggle="tab">ECARTS
                    ({{ inventoryInfoApiData.inventory_items_count_gaps }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('below')"
                                          v-bind:class="{ 'active': tab.below }" class="nav-link"
                                          data-toggle="tab">MANQUANTS
                    ({{ inventoryInfoApiData.inventory_items_count_below }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('above')"
                                          v-bind:class="{ 'active': tab.above }" class="nav-link"
                                          data-toggle="tab">SURPLUS
                    ({{ inventoryInfoApiData.inventory_items_count_above }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('notes')"
                                          v-bind:class="{ 'active': tab.notes }" class="nav-link"
                                          data-toggle="tab">OBSERVATIONS
                    ({{ inventoryInfoApiData.inventory_notes_count }})</a></li>
                </ul>
              </div>
            </div>
            <div class="profile-content">
              <div class="tab-content p-0">
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.home }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                                <a v-if="inventoryDocumentDownloadUrlApiData == null"
                                                   class="btn btn-white m-b-10 mr-2" @click="onDocumentDownloadClick"><i
                                                    v-if="!inventoryDocumentDownloadUrlApiDataIsLoading"
                                                    class="fa fa-download text-warning fa-fw fa-lg"></i><i
                                                    class="fa fa-spinner text-warning fa-fw fa-lg fa-spin"
                                                    v-if="inventoryDocumentDownloadUrlApiDataIsLoading"></i> Télécharger</a>
                                                <a v-if="inventoryDocumentDownloadUrlApiData != null &&  !inventoryDocumentDownloadUrlApiDataIsLoading &&  inventoryDocumentDownloadUrlApiData.pdf_url != null"
                                                   :href="inventoryDocumentDownloadUrlApiData.pdf_url" target="_blank"
                                                   class="btn btn-white m-b-10 mr-2"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                                <a v-if="inventoryDocumentDownloadUrlApiData != null &&  !inventoryDocumentDownloadUrlApiDataIsLoading &&  inventoryDocumentDownloadUrlApiData.excel_url != null"
                                                   :href="inventoryDocumentDownloadUrlApiData.excel_url" target="_blank"
                                                   class="btn btn-white m-b-10 mr-2"><i
                                                    class="fa fa-file-excel text-success fa-fw fa-lg"></i> Excel</a>
                                            </span>
                      <!--
                      <b-dropdown split text="Actions" class="btn-group pull-right hidden-print m-r-10" variant="default">
                          <b-dropdown-item href="#">Action 1</b-dropdown-item>
                          <b-dropdown-item href="#">Action 2</b-dropdown-item>
                          <b-dropdown-item href="#">Action 3</b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#">Action 4</b-dropdown-item>
                      </b-dropdown>
                      -->
                      <h1>TOUS LES ARTICLES<br/>
                        <small>{{ inventoryInfoApiData.server_side_code }} du
                          {{ inventoryInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header" style="background: blue; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'INFORMATIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header" style="background: red; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'CORRECTIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.company_name }}</strong><br/>
                          {{ inventoryInfoApiData.company_address_line }}<br/>
                          {{ inventoryInfoApiData.company_cluster_name }},
                          {{ inventoryInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ inventoryInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Emplacement</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.warehouse_name }}</strong><br/>
                          {{ inventoryInfoApiData.location_name }}<br/>
                        </address>
                      </div>
                      <div class="invoice-date">
                        <small>Validation</small>
                        <div class="date text-inverse m-t-5">

                        </div>
                        <div class="invoice-detail">
                          Initié Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.auditor_name }}</span><br/>
                          Validé Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approved_by_name }}</span><br/>
                          Date de Validation <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approval_date }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-header -->
                    <h3>{{ inventoryInfoApiData.inventory_items_count }} Article(s)</h3>
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th class="text-left">DESIGNATION</th>
                            <th class="text-center" width="15%">QTE EN MACHINE</th>
                            <th class="text-center" width="15%">QTE COMPTÉE</th>
                            <th class="text-right" width="15%">ÉCART CONSTATÉ</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_item,index) in inventoryInfoApiData.inventory_items"
                              :key="index">
                            <td><b>{{ index + 1 }}</b></td>
                            <td class="text-left"><b>{{ order_item.product_name }}</b></td>
                            <td class="text-center"><b>{{ order_item.theoretical_qty|formatDecimalNumber }}</b></td>
                            <td class="text-center"><b>{{ order_item.actual_qty|formatDecimalNumber }}</b></td>
                            <td class="text-right"><span class="label"
                                                         :class="{'label-success':order_item.gap == 0,'label-danger':order_item.gap < 0,'label-primary':order_item.gap > 0}"><b>{{ order_item.gap|formatDecimalNumber }}</b></span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <!--
                          <div class="invoice-price-row">
                              <div class="sub-price">
                                  <small>TOTAL TTC</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_amount_ttc|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-minus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>REGLEMENTS</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_total_payments|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-equals text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>RESTE A PAYER</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_remainder_payments|formatDecimalNumber}}</span>
                              </div>
                          </div>
                          -->
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>TOTAL VALEUR MARCHANDE</b></small>
                          <br/>
                          <span
                              class="f-w-600 text-yellow">{{ inventoryInfoApiData.total_amount_actual|formatMoney }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ inventoryInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ inventoryInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ inventoryInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.equal }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>BON COMPTE<br/>
                        <small>{{ inventoryInfoApiData.server_side_code }} du
                          {{ inventoryInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header" style="background: blue; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'INFORMATIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header" style="background: red; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'CORRECTIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.company_name }}</strong><br/>
                          {{ inventoryInfoApiData.company_address_line }}<br/>
                          {{ inventoryInfoApiData.company_cluster_name }},
                          {{ inventoryInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ inventoryInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Emplacement</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.warehouse_name }}</strong><br/>
                          {{ inventoryInfoApiData.location_name }}<br/>
                        </address>
                      </div>
                      <div class="invoice-date">
                        <small>Validation</small>
                        <div class="date text-inverse m-t-5">

                        </div>
                        <div class="invoice-detail">
                          Initié Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.auditor_name }}</span><br/>
                          Validé Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approved_by_name }}</span><br/>
                          Date de Validation <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approval_date }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-header -->
                    <h3>{{ inventoryInfoApiData.inventory_items_count_equal }} Article(s)</h3>
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th class="text-left">DESIGNATION</th>
                            <th class="text-center" width="15%">QTE EN MACHINE</th>
                            <th class="text-center" width="15%">QTE COMPTÉE</th>
                            <th class="text-right" width="15%">ÉCART CONSTATÉ</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_item,index) in inventoryInfoApiData.inventory_items_equal"
                              :key="index">
                            <td><b>{{ index + 1 }}</b></td>
                            <td class="text-left"><b>{{ order_item.product_name }}</b></td>
                            <td class="text-center"><b>{{ order_item.theoretical_qty|formatDecimalNumber }}</b></td>
                            <td class="text-center"><b>{{ order_item.actual_qty|formatDecimalNumber }}</b></td>
                            <td class="text-right"><span class="label"
                                                         :class="{'label-success':order_item.gap == 0,'label-danger':order_item.gap < 0,'label-primary':order_item.gap > 0}"><b>{{ order_item.gap|formatDecimalNumber }}</b></span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <!--
                          <div class="invoice-price-row">
                              <div class="sub-price">
                                  <small>TOTAL TTC</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_amount_ttc|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-minus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>REGLEMENTS</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_total_payments|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-equals text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>RESTE A PAYER</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_remainder_payments|formatDecimalNumber}}</span>
                              </div>
                          </div>
                          -->
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>VALEUR ECART</b></small>
                          <br/>
                          <span class="f-w-600 text-yellow">{{ 0|formatMoney }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ inventoryInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ inventoryInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ inventoryInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.gaps }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>ECARTS<br/>
                        <small>{{ inventoryInfoApiData.server_side_code }} du
                          {{ inventoryInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header" style="background: blue; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'INFORMATIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header" style="background: red; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'CORRECTIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.company_name }}</strong><br/>
                          {{ inventoryInfoApiData.company_address_line }}<br/>
                          {{ inventoryInfoApiData.company_cluster_name }},
                          {{ inventoryInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ inventoryInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Emplacement</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.warehouse_name }}</strong><br/>
                          {{ inventoryInfoApiData.location_name }}<br/>
                        </address>
                      </div>
                      <div class="invoice-date">
                        <small>Validation</small>
                        <div class="date text-inverse m-t-5">

                        </div>
                        <div class="invoice-detail">
                          Initié Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.auditor_name }}</span><br/>
                          Validé Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approved_by_name }}</span><br/>
                          Date de Validation <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approval_date }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-header -->
                    <h3>{{ inventoryInfoApiData.inventory_items_count_gaps }} Article(s)</h3>
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th class="text-left">DESIGNATION</th>
                            <th class="text-center" width="15%">QTE EN MACHINE</th>
                            <th class="text-center" width="15%">QTE COMPTÉE</th>
                            <th class="text-right" width="15%">ÉCART CONSTATÉ</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_item,index) in inventoryInfoApiData.inventory_items_gaps"
                              :key="index">
                            <td><b>{{ index + 1 }}</b></td>
                            <td class="text-left"><b>{{ order_item.product_name }}</b></td>
                            <td class="text-center"><b>{{ order_item.theoretical_qty|formatDecimalNumber }}</b></td>
                            <td class="text-center"><b>{{ order_item.actual_qty|formatDecimalNumber }}</b></td>
                            <td class="text-right"><span class="label"
                                                         :class="{'label-success':order_item.gap == 0,'label-danger':order_item.gap < 0,'label-primary':order_item.gap > 0}"><b>{{ order_item.gap|formatDecimalNumber }}</b></span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <!--
                          <div class="invoice-price-row">
                              <div class="sub-price">
                                  <small>TOTAL TTC</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_amount_ttc|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-minus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>REGLEMENTS</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_total_payments|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-equals text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>RESTE A PAYER</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_remainder_payments|formatDecimalNumber}}</span>
                              </div>
                          </div>
                          -->
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>VALEUR ECART</b></small>
                          <br/>
                          <span
                              class="f-w-600 text-yellow">{{ (inventoryInfoApiData.total_amount_above - inventoryInfoApiData.total_amount_below)|formatMoney }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ inventoryInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ inventoryInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ inventoryInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.below }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>MANQUANTS<br/>
                        <small>{{ inventoryInfoApiData.server_side_code }} du
                          {{ inventoryInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header" style="background: blue; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'INFORMATIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header" style="background: red; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'CORRECTIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.company_name }}</strong><br/>
                          {{ inventoryInfoApiData.company_address_line }}<br/>
                          {{ inventoryInfoApiData.company_cluster_name }},
                          {{ inventoryInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ inventoryInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Emplacement</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.warehouse_name }}</strong><br/>
                          {{ inventoryInfoApiData.location_name }}<br/>
                        </address>
                      </div>
                      <div class="invoice-date">
                        <small>Validation</small>
                        <div class="date text-inverse m-t-5">

                        </div>
                        <div class="invoice-detail">
                          Initié Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.auditor_name }}</span><br/>
                          Validé Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approved_by_name }}</span><br/>
                          Date de Validation <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approval_date }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-header -->
                    <h3>{{ inventoryInfoApiData.inventory_items_count_below }} Article(s)</h3>
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th class="text-left">DESIGNATION</th>
                            <th class="text-center" width="15%">QTE EN MACHINE</th>
                            <th class="text-center" width="15%">QTE COMPTÉE</th>
                            <th class="text-right" width="15%">ÉCART CONSTATÉ</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_item,index) in inventoryInfoApiData.inventory_items_below"
                              :key="index">
                            <td><b>{{ index + 1 }}</b></td>
                            <td class="text-left"><b>{{ order_item.product_name }}</b></td>
                            <td class="text-center"><b>{{ order_item.theoretical_qty|formatDecimalNumber }}</b></td>
                            <td class="text-center"><b>{{ order_item.actual_qty|formatDecimalNumber }}</b></td>
                            <td class="text-right"><span class="label"
                                                         :class="{'label-success':order_item.gap == 0,'label-danger':order_item.gap < 0,'label-primary':order_item.gap > 0}"><b>{{ order_item.gap|formatDecimalNumber }}</b></span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <!--
                          <div class="invoice-price-row">
                              <div class="sub-price">
                                  <small>TOTAL TTC</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_amount_ttc|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-minus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>REGLEMENTS</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_total_payments|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-equals text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>RESTE A PAYER</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_remainder_payments|formatDecimalNumber}}</span>
                              </div>
                          </div>
                          -->
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>VALEUR MARCHANDE</b></small>
                          <br/>
                          <span
                              class="f-w-600 text-yellow">{{ inventoryInfoApiData.total_amount_below|formatMoney }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ inventoryInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ inventoryInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ inventoryInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.above }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>SURPLUS<br/>
                        <small>{{ inventoryInfoApiData.server_side_code }} du
                          {{ inventoryInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header" style="background: blue; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'INFORMATIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header" style="background: red; padding: 5px;"
                         v-if="inventoryInfoApiData.type_code == 'CORRECTIVE'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{inventoryInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.company_name }}</strong><br/>
                          {{ inventoryInfoApiData.company_address_line }}<br/>
                          {{ inventoryInfoApiData.company_cluster_name }},
                          {{ inventoryInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ inventoryInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Emplacement</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ inventoryInfoApiData.warehouse_name }}</strong><br/>
                          {{ inventoryInfoApiData.location_name }}<br/>
                        </address>
                      </div>
                      <div class="invoice-date">
                        <small>Validation</small>
                        <div class="date text-inverse m-t-5">

                        </div>
                        <div class="invoice-detail">
                          Initié Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.auditor_name }}</span><br/>
                          Validé Par <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approved_by_name }}</span><br/>
                          Date de Validation <span
                            class="date text-inverse m-t-5">{{ inventoryInfoApiData.approval_date }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-header -->
                    <h3>{{ inventoryInfoApiData.inventory_items_count_above }} Article(s)</h3>
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th class="text-left">DESIGNATION</th>
                            <th class="text-center" width="15%">QTE EN MACHINE</th>
                            <th class="text-center" width="15%">QTE COMPTÉE</th>
                            <th class="text-right" width="15%">ÉCART CONSTATÉ</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_item,index) in inventoryInfoApiData.inventory_items_above"
                              :key="index">
                            <td><b>{{ index + 1 }}</b></td>
                            <td class="text-left"><b>{{ order_item.product_name }}</b></td>
                            <td class="text-center"><b>{{ order_item.theoretical_qty|formatDecimalNumber }}</b></td>
                            <td class="text-center"><b>{{ order_item.actual_qty|formatDecimalNumber }}</b></td>
                            <td class="text-right"><span class="label"
                                                         :class="{'label-success':order_item.gap == 0,'label-danger':order_item.gap < 0,'label-primary':order_item.gap > 0}"><b>{{ order_item.gap|formatDecimalNumber }}</b></span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <!--
                          <div class="invoice-price-row">
                              <div class="sub-price">
                                  <small>TOTAL TTC</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_amount_ttc|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-minus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>REGLEMENTS</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_total_payments|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-equals text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>RESTE A PAYER</small>
                                  <span class="text-inverse">{{inventoryInfoApiData.order_remainder_payments|formatDecimalNumber}}</span>
                              </div>
                          </div>
                          -->
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>VALEUR MARCHANDE</b></small>
                          <br/>
                          <span
                              class="f-w-600 text-yellow">{{ inventoryInfoApiData.total_amount_above|formatMoney }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ inventoryInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ inventoryInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ inventoryInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.notes }">
                  <div class="invoice">
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>OBSERVATIONS<br/>
                        <small>{{ inventoryInfoApiData.server_side_code }} du
                          {{ inventoryInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <h3>{{ inventoryInfoApiData.inventory_notes_count }} Observation(s)</h3>
                    <div class="invoice-content">
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th class="text-left">DATE</th>
                            <th class="text-center">AGENT</th>
                            <th class="text-right">OBSERVATION</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_note,index) in inventoryInfoApiData.inventory_notes"
                              :key="index">
                            <td class="text-left">{{ order_note.date }}</td>
                            <td class="text-center">{{ order_note.writer }}</td>
                            <td class="text-right">{{ order_note.note }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ inventoryInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ inventoryInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ inventoryInfoApiData.company_email }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/stock/stock-dashboard-page-api'

export default {
  name: 'inventory-component',
  components: {},
  props: ['itemId'],
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    //
    show: function (x) {
      this.tab.home = false;
      this.tab.equal = false;
      this.tab.gaps = false;
      this.tab.below = false;
      this.tab.above = false;
      this.tab.notes = false;

      switch (x) {
        case 'home':
          this.tab.home = true;
          break;
        case 'equal':
          this.tab.equal = true;
          break;
        case 'gaps':
          this.tab.gaps = true;
          break;
        case 'below':
          this.tab.below = true;
          break;
        case 'above':
          this.tab.above = true;
          break;
        case 'notes':
          this.tab.notes = true;
          break;
        default:
          this.tab.home = true;
          break;
      }
    },

    onDocumentDownloadClick() {
      this.getInventoryDocumentDownloadUrlData(this.itemId);
    },

    //Other Methods
    async getInventoryInfoData(inventoryId) {
      this.inventoryInfoApiDataIsLoading = true;
      this.inventoryInfoApiDataSuccessMessage = null;
      this.inventoryInfoApiDataWarningMessage = null;
      this.inventoryInfoApiDataErrorMessage = null;
      this.inventoryInfoApiDataValidationErrors = [];
      try {
        const response = await pageApi.getInventoryInfoData(inventoryId);
        //console.log(response);
        this.inventoryInfoApiData = response.data;
        this.inventoryInfoApiDataIsLoading = false;
        this.inventoryInfoApiDataSuccessMessage = null;
        this.inventoryInfoApiDataWarningMessage = null;
        this.inventoryInfoApiDataErrorMessage = null;
        this.inventoryInfoApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.inventoryInfoApiDataIsLoading = false;
          this.inventoryInfoApiDataSuccessMessage = null;
          this.inventoryInfoApiDataWarningMessage = null;
          this.inventoryInfoApiDataErrorMessage = error.response.data.message;
          this.inventoryInfoApiDataValidationErrors = [];
        } else {
          this.inventoryInfoApiDataIsLoading = false;
          this.inventoryInfoApiDataSuccessMessage = null;
          this.inventoryInfoApiDataWarningMessage = null;
          this.inventoryInfoApiDataErrorMessage = error.message;
          this.inventoryInfoApiDataValidationErrors = [];
        }
      }
    },

    async getInventoryDocumentDownloadUrlData(inventoryId) {
      this.inventoryDocumentDownloadUrlApiDataIsLoading = true;
      this.inventoryDocumentDownloadUrlApiDataErrorMessage = null;
      try {
        const response = await pageApi.getInventoryDocumentDownloadUrlData(inventoryId);
        //console.log(response);
        this.inventoryDocumentDownloadUrlApiData = response.data;
        this.inventoryDocumentDownloadUrlApiDataIsLoading = false;
        this.inventoryDocumentDownloadUrlApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.inventoryDocumentDownloadUrlApiDataIsLoading = false;
          this.inventoryDocumentDownloadUrlApiDataErrorMessage = error.response.data.message;
        } else {
          this.inventoryDocumentDownloadUrlApiDataIsLoading = false;
          this.inventoryDocumentDownloadUrlApiDataErrorMessage = error.message;
        }
      }
    },

  },
  data: function () {

    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      //
      tab: {
        home: true,
        equal: false,
        gaps: false,
        below: false,
        above: false,
        notes: false,
      },

      //API Data
      //
      inventoryInfoApiData: null,
      inventoryInfoApiDataIsLoading: false,
      inventoryInfoApiDataSuccessMessage: null,
      inventoryInfoApiDataWarningMessage: null,
      inventoryInfoApiDataErrorMessage: null,
      inventoryInfoApiDataValidationErrors: [],
      //
      inventoryDocumentDownloadUrlApiData: null,
      inventoryDocumentDownloadUrlApiDataIsLoading: false,
      inventoryDocumentDownloadUrlApiDataErrorMessage: null,
    };
  },
  created: function () {
    if (this.itemId) {
      this.getInventoryInfoData(this.itemId);
    }
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
